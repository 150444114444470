import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Box } from 'theme-ui'

const FormRecaptcha = ({ onChange, name, id, siteKey, required }) => {
  if (!siteKey) {
    console.warn('ReCAPTCHA siteKey is missing for field:', name)
    return null
  }

  return (
    <Box mb={3}>
      {/* <div data-netlify-recaptcha="true"></div> */}
      <ReCAPTCHA
        sitekey={siteKey}
        required={required}
        onChange={(value) => onChange({
          target: {
            name,
            value: value || ''
          }
        })}
      />
    </Box>
  )
}

export default FormRecaptcha